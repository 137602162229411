<template>
  <div class="production-track">
    <div class="map-wrapper">
      <div class="opts-area"
           v-if="tableData.length">
        <el-tooltip effect="dark"
                    content="播放">
          <i class="el-icon-video-play"
             @click="startPlay"></i>
        </el-tooltip>
        <el-tooltip effect="dark"
                    content="暂停">
          <i class="el-icon-video-pause"
             @click="pausePlay"></i>
        </el-tooltip>
        <el-tooltip effect="dark"
                    content="继续">
          <i class="el-icon-refresh-right"
             @click="resumePlay"></i>
        </el-tooltip>
        <el-tooltip effect="dark"
                    content="停止">
          <i class="el-icon-refresh"
             @click="reset"></i>
        </el-tooltip>
        <el-tooltip effect="dark"
                    content="导出文件">
          <i class="el-icon-download"
             @click="exportFile"></i>
        </el-tooltip>
      </div>
      <div id="map"></div>
    </div>
    <div class="operations"
         ref="operation">
      <el-form :model="form"
               inline
               ref="form">
        <el-form-item label="车牌号码"
                      :rules="{required: true,message:'请选择车辆信息'}"
                      prop="carid">
          <el-select filterable
                     v-model="form.carid">
            <el-option v-for="n in carList"
                       :key="n.carId"
                       :label="n.number"
                       :value="n.carDeviceId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开始时间"
                      :rules="{required: true,message:'请选择开始时间'}"
                      prop="beginTime">
          <el-date-picker type="datetime"
                          value-format="yyyyMMddHHmmss"
                          v-model="form.beginTime"></el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间"
                      :rules="{required: true,message:'请选择结束时间'}"
                      prop="endTime">
          <el-date-picker type="datetime"
                          value-format="yyyyMMddHHmmss"
                          v-model="form.endTime"></el-date-picker>
        </el-form-item>
        <el-button type="primary"
                   @click="fetchTableData">查询</el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import axios from 'axios'
import { mapCenter, restfulUrl } from '@/config'
import { download } from '@/utils'

export default {
  name: 'production-track',

  data() {
    const today = dayjs()
    const endTime = today.format('YYYYMMDDHHmmss')
    const beginTime = endTime.substr(0, 8) + '000000'
    return {
      form: {
        func: 1015,
        carid: ' ',
        beginTime,
        endTime,
        // carid: '015919122081',
        // beginTime: '20200417052435',
        // endTime: '20200417060231',
        current: 1,
        size: 3000,
      },
      tableData: [],
      total: 0,
      carList: [],
      showWindow: true,
      lineArr: [],
      carWindow: null,
      downloading: false,
    }
  },

  mounted() {
    this.map = new window.AMap.Map('map', {
      resizeEnable: true,
      center: mapCenter,
      zoom: 10,
    })
  },

  async created() {
    const data = await this.$http({
      url: this.$http.adornUrl('/production/car/list'),
      method: 'post',
      data: {},
    })
    this.carList = data.datas
  },

  methods: {
    async fetchTableData() {
      if (this.marker) {
        this.marker.off('move', function (e) {})
      }
      this.map.clearMap()
      this.marker = null
      this.carWindow = null
      this.tableData = []
      this.lineArr = []
      this.form.current = 1
      this.$refs.form.validate((valid) => {
        if (valid) {
          const start = dayjs(this.form.beginTime)
          const end = dayjs(this.form.endTime)
          if (start > end) {
            return this.$opts.alert('开始时间不能晚于结束时间!')
          }
          if (start.month() !== end.month()) {
            return this.$opts.alert('开始时间与结束时间不能跨月!')
          }
          if (end.diff(start, 'day') > 3) {
            return this.$opts.alert('开始时间与结束时间不能超过3天!')
          }
          this.appendTableData()
        }
      })
    },

    appendTableData() {
      axios
        .get(restfulUrl + '/api.json', {
          params: this.form,
        })
        .then((res) => {
          if (res.data.code === 1) {
            this.tableData = res.data.list
            this.createTrack()
          } else {
            this.$opts.alert(res.data.message)
          }
        })
    },

    createTrack() {
      const AMap = window.AMap
      let currentPoint = this.tableData[0]
      const coordinates = [currentPoint.lng, currentPoint.lat]
      this.marker = new AMap.Marker({
        map: this.map,
        position: coordinates,
        content: '<img src="/img/state-light-2.png" style="height:24px;">',
        anchor: 'bottom-center',
        offset: new AMap.Pixel(0, 10),
      })
      if (!this.centered) {
        this.map.setZoomAndCenter(13, coordinates)
        this.centered = true
      }

      // 车辆经过路线样式
      this.tableData.forEach((item) => {
        this.lineArr.push([item.lng, item.lat])
      })

      // 创建跟速度信息展示框
      this.carWindow = new AMap.InfoWindow({
        offset: new AMap.Pixel(6, -25),
        content: '',
      })

      // 绘制轨迹
      //   this.pathPolyline = this.initializePaths(this.tableData)

      // 绘制轨迹
      let polyline = new AMap.Polyline({
        map: this.map,
        path: this.lineArr,
        showDir: true,
        strokeColor: '#28F', // 线颜色
        // strokeOpacity: 1,     //线透明度
        strokeWeight: 6, // 线宽
        // strokeStyle: "solid"  //线样式
      })

      let passedPolyline = new AMap.Polyline({
        map: this.map,
        // path: this.lineArr,
        strokeColor: '#AF5', // 线颜色
        // strokeOpacity: 1,     //线透明度
        strokeWeight: 6, // 线宽
        // strokeStyle: "solid"  //线样式
      })

      let that = this
      this.marker.on('moving', function (e) {
        passedPolyline.setPath(e.passedPath)
        that.setVehicleSpeedInWidowns(e.passedPath.length - 1)
      })

      // 地图自适应缩放
      this.map.setFitView()
    },

    setVehicleSpeedInWidowns(index) {
      let time = dayjs(this.tableData[index].systemtime).format(
        'YYYY年MM月DD日HH时mm分ss秒'
      )
      let content = `
					<div style="padding: 0 10px;line-height: 25px;font-size: 14px;">
                        <div>时间：${time}</div>
						<div>所在经度：${this.tableData[index].lng}</div>
						<div>所在纬度：${this.tableData[index].lat}</div>
						<div>速度：${this.tableData[index].speed.toFixed(2)} KM/h</div>
					</div>
				`

      this.carWindow.setContent(content)

      let position = new window.AMap.LngLat(
        this.tableData[index].lng,
        this.tableData[index].lat
      )
      this.carWindow.open(this.map, position)
    },

    reset() {
      this.marker.stopMove()
    },

    startPlay() {
      this.marker.moveAlong(this.lineArr, 200)
    },

    pausePlay() {
      this.marker.pauseMove()
    },

    resumePlay() {
      this.marker.resumeMove()
    },

    exportFile() {
      if (this.downloading) return
      this.downloading = true
      download(
        '/production/carTracks/download',
        {
          carDeviceId: this.form.carid,
          startDate: dayjs(this.form.beginTime).valueOf(),
          endDate: dayjs(this.form.endTime).valueOf(),
        },
        '车辆轨迹信息'
      )
        .then(() => {
          this.downloading = false
        })
        .catch(() => {
          this.downloading = false
        })
    },
  },

  beforeDestroy() {},
}
</script>

  <style lang="scss" scoped>
.production-track {
  padding: 30px;
  .map-wrapper {
    height: calc(85vh - 20px);
    margin-bottom: 20px;
    min-height: 300px;
    border: 1px solid #ccc;
    border-radius: 4px;
    position: relative;
    #map {
      width: 100%;
      height: 100%;
    }
    .opts-area {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 100px;
      height: 30px;
      background: #fff;
      z-index: 10;
      box-shadow: 0px 2px 4px 0px rgba(66, 66, 66, 0.51);
      border-radius: 2px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      i {
        font-size: 18px;
        cursor: pointer;
        color: #999;
        transition: color 0.2s;
        &:hover {
          font-size: 19px;
          color: #333;
        }
      }
    }
  }
  .operations {
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(103, 103, 103, 0.1);
    border-radius: 4px;
    //   border-bottom: 1px solid #eeeeee;
    padding: 15px 20px;
    .el-form-item {
      margin-right: 30px;
      margin-bottom: 1px;
    }
  }
}
</style>
